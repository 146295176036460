<template>
  <div class="container">
    <div @click="goback" style="font-size:14px;margin-bottom:10px;cursor: pointer;width:50px">
      <i class="el-icon-back"></i>
      返回
    </div>
    <div class="search" style="display:flex">
      <el-form ref="form" style="flex:1" :inline="true" :model="search" class="demo-form-inline">
        <el-form-item>
          <el-input v-model="search.kname" @change="Search" placeholder="名称"></el-input>
        </el-form-item>
      </el-form>
      <div>
        <el-button type="primary" size="small" @click="AddAppShow">新建</el-button>
      </div>
    </div>
    <el-table border v-bind:data="list">
      <el-table-column label="终端编号">
        <template v-slot="scope">
          <span>{{ scope.row.client_app_code }}</span>
        </template>
      </el-table-column>
      <el-table-column label="品牌编号">
        <template v-slot="scope">
          <span>{{ scope.row.client_brand_code }}</span>
        </template>
      </el-table-column>
      <el-table-column label="操作">
        <template v-slot="scope">
          <el-button type="danger" size="small" @click="DeleteAppShow(scope.row)">删除</el-button>
        </template>
      </el-table-column>
    </el-table>
    <el-pagination
      @current-change="handleCurrentChange"
      v-model:currentPage="search.page"
      :page-size="search.limit"
      layout="total, prev, pager, next"
      :total="total">
    </el-pagination>

    <el-dialog :title="title+'品牌展示'" v-model="dialogFormVisible" width="600px">
      <el-form :model="form" :rules="rules" ref="form">
        <el-form-item label="品牌" prop="" label-width="100px">
          <el-select v-model="form.client_brand_code">
            <el-option
              v-for="item in brandlist"
              :key="item.code"
              :label="item.name"
              :value="item.code">
            </el-option>
          </el-select>
        </el-form-item>
      </el-form>
      <template #footer>
        <span class="dialog-footer">
          <el-button @click="dialogFormVisible = false">取 消</el-button>
          <el-button type="primary" @click="Validate(Addconfirm)">{{edit?'修改':'新增'}}</el-button>
        </span>
      </template>
    </el-dialog>

  </div>
</template>

<script type="text/ecmascript-6">
export default {
  name: '',
  props: {},
  setup () {
    return {
    }
  },
  mixins: [],
  components: {},
  data () {
    return {
      search: {
        page: 1,
        limit: 10,
        client_app_code: ''
      },
      list: [],
      total: 0,
      title: '',
      dialogFormVisible: false,
      edit: false,
      form: {
        client_app_code: '',
        client_brand_code: '',
      },
      rules: {
        name: [{ required: true, message: '请输入名称', trigger: 'blur' }],
      },
      brandlist: [],
    }
  },
  computed: {},
  watch: {},
  methods: {
    GetBrand () {
      this.api.BrandList({
        page: 1,
        limit: 100,
      }).then(res => {
        if (res.data.code == 200) {
          this.brandlist = res.data.data.data
          return
        }
        this.$message.error('获取失败' + res.data.msg)
      })
    },
    handleCurrentChange(val) {
      this.search.page = val
      this.Search()
    },
    Search () {
      this.api.BrandAppList(this.search).then(res => {
        if (res.data.code == 200) {
          this.list = res.data.data.data
          this.total = res.data.data.page_info.total
          return
        }
        this.$message.error('获取失败' + res.data.msg)
      })
    },
    Validate: function (call) {
      this.$refs['form'].validate(res => {
        if (res && call) {
          call()
        }
      })
    },
    Addconfirm () {
        console.log('search参数',this.search.client_app_code)
      this.api.BrandAppUpdate({
        client_app_code: this.search.client_app_code,
        client_brand_code: this.form.client_brand_code
      }).then(res => {
        if (res.data.code == 200) {
          this.$message.success(this.title + '成功')
          this.dialogFormVisible = false
          this.Search()
          return
        }
        this.$message.error(this.title+ '失败' + res.data.msg)
      })
    },
    AddAppShow () {
      this.edit = false
      this.title = '创建'
      this.dialogFormVisible = true
    },
    DeleteAppShow (item) {
      this.$confirm('是否删除该app展示品牌?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
        // 删除请求
        this.api.BrandAppDelete({
          client_app_code: item.client_app_code,
          client_brand_code: item.client_brand_code,
        }).then(res => {
          if (res.data.code == 200) {
            this.$message({
              type: 'success',
              message: '删除成功!'
            })
            this.Search()
            return
          }
          this.$message.error('删除失败' + res.data.msg)
        })
      }).catch(() => {
        this.$message({
          type: 'info',
          message: '取消删除'
        });
      })
    },
    goback (){
      this.$router.push('/iot/app/appvesion')
    },
  },
  filters: {},
  mounted () {
    this.search.client_app_code = this.$route.params.code
    this.Search()
  },
  created () {
    this.GetBrand()
  }
}
</script>

<style scoped lang="stylus" rel="stylesheet/stylus">
</style>
